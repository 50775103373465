import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import PageHeader from './global/page-header';
import Navbar from './global/navbar';
import Footer from './global/footer';

function Reviews() {
  const { rpdata } = useContext(GlobalDataContext);


  return (
    <div>
      <Navbar />
      <div className="md:max-w-full w-full">
        <PageHeader
          headertitle="Reviews"
          Subheader="Reviews"
          bgimg={rpdata?.stock?.[9]}
        />
      </div>
      <div className="w-[90%] md:w-4/5 mx-auto py-[100px]">
        <h1 className="text-center pb-4">
          {rpdata?.labels?.general?.titleReviews}
        </h1>

        {/* reviews del link de elfsight  */}
        <div className={`${rpdata?.reviews?.links?.[0]} mt-10`}></div>
      </div>
      <Footer />
    </div>
  );
}

export default Reviews;
